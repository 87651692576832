<template>
  <tableau view="PLheadofpractice/sheet0" :inject-doctor-name="true" doctor-param-name="practice_head_2" :inject-user-id="true" />
</template>

<script>
import Tableau from "./Tableau"

export default {
  components: { Tableau }
}
</script>
